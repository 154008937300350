<template>
  <div class="pt-5">
    <v-container>
      <v-row>
        <v-col
          class="pa-10"
          cols="12"
          offset="0"
          offset-sm="1"
          sm="5"
          offset-md="1"
          md="5"
          offset-lg="1"
          lg="5"
          offset-xl="1"
          xl="5"
        >
          <h1 class="oswald">Kawasaki Rewards</h1>
          <h2 class="oswald">Welcome to the new Kawasaki Rewards!</h2>
          <p class="white--text mt-8 body-2">
            If you have any questions regarding the Kawasaki Rewards Program, please contact Rewards Headquarters
            between 8:30am - 5:00pm CT, Monday - Friday (excluding federal holidays).
            <span class="d-block mt-5" v-if="selectedProgram && selectedProgram.programEmail">
              <span class="d-sm-none d-md-flex">
                <span class="d-block">
                  BY EMAIL: <br />
                  <a :href="'mailto:' + selectedProgram.programEmail">{{ selectedProgram.programEmail }}</a>
                </span>
              </span>
              <span class="d-none d-sm-flex d-md-none">
                <span class="d-block">
                  BY EMAIL: <br />
                  <a :href="'mailto:' + selectedProgram.programEmail">Contact Rewards Headquarters</a>
                </span>
              </span>
            </span>
            <span class="d-block mt-5" v-if="selectedProgram && selectedProgram.programPhone">
              BY PHONE:<br />
              <a :href="'tel:' + selectedProgram.programPhone">{{ selectedProgram.programPhone | toPhoneNumber }}</a>
            </span>
          </p>
        </v-col>
        <v-col class="pa-10" cols="12" sm="6" md="6" lg="6" xl="4">
          <v-form v-if="!validCredentials" v-model="valid" @submit.stop.prevent="onSubmit">
            <v-card class="elevation-12">
              <v-toolbar dark color="info">
                <v-toolbar-title>
                  <span>Rewards Login</span>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <ApiError :errors="errors" :message="errorMessage"></ApiError>
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-text-field
                        v-model="form.username"
                        :rules="rules.username"
                        label="Email"
                        name="email"
                        prepend-icon="mdi-account"
                        type="text"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-text-field
                        v-model="form.password"
                        label="Password"
                        name="password"
                        hint="Passwords are case-sensitive"
                        prepend-icon="mdi-lock"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        :type="showPassword ? 'text' : 'password'"
                        autocomplete="off"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" align="right">
                      <router-link :to="{ name: 'forgot-password' }">Forgot Password</router-link>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        block
                        type="submit"
                        color="primary"
                        :loading="loading"
                        :disabled="loading || !isValidUsername"
                        >Login</v-btn
                      >
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" align="center">
                      <slot name="login_content">
                        <p>
                          New User? Click Register to setup a free account and join the Racer Rewards or Sales Rewards
                          programs.
                        </p>
                      </slot>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12">
                      <slot name="register">
                        <v-btn block type="button" color="secondary" :to="{ name: 'register' }">Register</v-btn>
                      </slot>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-form>

          <v-card v-else-if="!loading && participants && participants.length > 1" class="elevation-12">
            <v-toolbar dark color="info">
              <v-toolbar-title>
                <span> Select a program you are enrolled in ...</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on: on }">
                  <v-btn icon v-on="on" @click="onClear()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>{{ $i18n.translate("Cancel") }}</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              <v-list two-line subheader>
                <v-list-item v-for="participant in participants" :key="participant.id" @click="onSelect(participant)">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ participant.participantType.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ participant.fullName }}
                      <span v-if="participant.organization"> of {{ participant.organization.name }} </span>
                      - {{ participant.participantKey }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
          <v-card v-else-if="participants && participants.length == 0" class="elevation-12">
            <v-toolbar dark color="info">
              <v-toolbar-title>
                <span> You aren't enrolled in any program. </span>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert dense outlined type="error">
                If you think this is an error simply call {{ env_phone }}. Our customer care team is available between
                8:30am and 5:00pm (ct) Monday through Friday to assist.
              </v-alert>
            </v-card-text>
          </v-card>
          <v-card v-else class="elevation-12">
            <v-toolbar dark color="info">
              <v-toolbar-title>
                <span>Redirecting to your program ...</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on: on }">
                  <v-btn icon v-on="on" @click="onClear()">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>{{ $i18n.translate("Cancel") }}</span>
              </v-tooltip>
            </v-toolbar>
            <v-card-text>
              Redirecting to your program. If you are not redirected shortly, please cancel and retry login.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../../gapp-components/components/display/ApiError.vue";

export default {
  components: { ApiError },
  name: "Login",
  metaInfo: {
    title: "Login"
  },
  data() {
    return {
      errors: {},
      errorMessage: null,
      valid: false,
      loading: false,
      validCredentials: false,
      form: {},
      showPassword: false,
      rules: {
        username: [v => !!v || "Username or Email is required"]
      },
      participants: [],
      unauthorizedError: false,
      program: null
    };
  },
  methods: {
    onSelect(participant) {
      return this.$auth
        .storeSelectParticipant(participant)
        .then(() => {
          this.$router.push({ name: "dashboard" }).catch(error => {
            console.log(error);
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    onClear() {
      this.participants = [];
      this.form.password = "";
      this.validCredentials = false;
    },
    onSubmit() {
      // if invalid, prevent submission
      if (!this.valid) {
        return;
      }

      // reset errors
      this.errors = {};

      // Set loading spinner
      this.loading = true;

      // clear existing errors
      this.$auth.logout();

      // submit login request
      this.$auth
        .login(this.form.username, this.form.password)
        .then(() => {
          // determine if there are multiple programs for this user
          return this.$auth.storeAvailableParticipants();
        })
        .then(() => {
          this.participants = this.availableParticipants;
          this.validCredentials = true;
          if (this.participants && this.participants.length == 1) {
            return this.onSelect(this.participants[0]);
          } else if (this.participants && this.participants.length > 1) {
            this.loading = false;
          } else {
            this.loading = false;
            return Promise.reject("No participants found");
          }
        })
        .catch(e => {
          this.loading = false;
          this.errors = this.$api.getErrorsFromResponse(e);
          if (this.$api.isUnauthorized(e)) {
            this.errorMessage = "Invalid username or password";
          } else if (this.$api.isCredentialsExpired(e)) {
            this.errorMessage = "Your credentials have expired, redirecting ...";
            setTimeout(() => {
              let key = this.$api.getCredentialsExpirationKey(e);
              return this.$router.push({ name: "credentials-expired", query: { key: key } });
            }, 1000);
          }
          this.onClear();
        });
    }
  },
  computed: {
    ...mapGetters(["availableParticipants", "selectedProgram"]),
    isValidUsername() {
      return this.form && this.form.username;
    }
  }
};
</script>
